import React, {useEffect} from "react";
import {Nav, Navbar} from "react-bootstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import {getCookieExpiration} from "../../utils";

export function BattleHeader() {
    const [cookies, setCookie, removeCookie] = useCookies(['groupId']);
    let {groupId} = useParams()
    let history = useHistory()

    useEffect(() => {
        if (groupId) {
            setCookie("groupId", groupId, {sameSite: true, path: '/', maxAge: getCookieExpiration()})
        }
    }, [groupId, setCookie])


    const clearGroup = (event) => {
        removeCookie("groupId", {sameSite: true, path: '/', maxAge: getCookieExpiration()})
        cookies.groupId = undefined;
        event.preventDefault()
        history.push("/battles")
    }

    return (
        <Navbar variant="dark" expand="lg">
            <Navbar.Brand as={Link} to={{pathname: "/battles" + (groupId ? "/" + groupId : ""), search: "", page: 0}}>Albion Killboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <Nav.Link as={Link} to={{pathname: "/battles" + (groupId ? "/" + groupId : ""), search: "", page: 0, type: ""}}>Battles</Nav.Link>
                    <Nav.Link as={Link} to={{pathname: "/crystals", search: "", page: 0}}>Crystals</Nav.Link> New!
                    <Nav.Link as={Link} to={{pathname: "/group"}}>Create your group</Nav.Link>
                </Nav>

                <Nav className="flex-grow-1 d-flex text-center">
                    {groupId &&
                    <Navbar.Text className="nav-variation">Group: <strong>{groupId}</strong> <Link to=" " onClick={clearGroup}>clear</Link></Navbar.Text>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
