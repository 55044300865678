import {Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {BattleHeader} from "./BattleHeader";
import {BattleSearch} from "./BattleSearch";
import React, {useEffect, useState} from "react";
import {useQueryState} from "react-router-use-location-state";
import {Battle, PagingLinks, SearchLinks} from "./Battles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FormattedDate, FormattedNumber, FormattedTime} from "react-intl";
import {Table} from "react-bootstrap";
import {useCookies} from "react-cookie";

export function Crystals() {
    const [page, setPage] = useQueryState("page", 0);
    const [search, setSearch] = useQueryState("search", "");
    const [playersCount, setPlayersCount] = useQueryState("playersCount", "");
    const [startDate, setStartDate] = useQueryState("startDate", "");
    const [items, setItems] = useState([])
    let {path} = useRouteMatch();

    const onSearch = (result, groupId, playersCount, startDate) => {
        setPage(0)
        setItems([])
        if (!result) {
            //removeCookie("search", {sameSite: true, path: '/'})
        }

        setSearch(result);
        setPlayersCount(playersCount ? playersCount : "")
        setStartDate(startDate)
    }
    return (
        <Switch>
            <Route exact path={path}>
                <BattleHeader/>
                <BattleSearch onSubmit={onSearch} value={search} playersCount={playersCount} startDate={startDate}/>
                <CrystalList search={search} limit={50} setPage={setPage} page={page} items={items} setItems={setItems} playersCount={playersCount} startDate={startDate} type="CRYSTAL"/>
            </Route>
            <Route path={`${path}/:battleId(\\d+)`}>
                <BattleHeader onSubmit={onSearch} value={search}/>
                <BattleSearch onSubmit={onSearch} value={search} playersCount={playersCount} startDate={startDate}/>
                <Battle/>
            </Route>
        </Switch>
    )
}


export function CrystalList(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState(props.items);
    const [cookies] = useCookies(['groupId']);
    const {setItems: setParentItems} = props;
    let history = useHistory()

    let {url} = useRouteMatch();
    const location = useLocation()
    let {groupId} = useParams()

    useEffect(() => {
        setIsLoaded(false);
        setError(null);
        let actualGroup = groupId === undefined ? "" : groupId;
        let url = `${process.env.REACT_APP_API_ROOT}/battles/?page=${encodeURIComponent(props.page)}&limit=${encodeURIComponent(props.limit)}&search=${encodeURIComponent(props.search)}&group=${actualGroup}&startDate=${props.startDate}&type=${props.type}`;

        fetch(url)
            .then(async (result) => {
                if (result.ok) {
                    result = await result.json()
                    setItems(result);
                    if (setParentItems) {
                        setParentItems(result)
                    }
                    setIsLoaded(true);
                } else {
                    setIsLoaded(true)
                    console.error(result.statusText)
                    setError("Error fetching data. Something wrong. Maybe try it later.")
                }
            })
            .catch(
                (error) => {
                    console.error(error);
                    setIsLoaded(true);
                    setError("Error fetching data. Something wrong. Maybe try it later.")
                }
            )
    }, [props.type, props.page, props.limit, props.search, props.playersCount, props.startDate, setParentItems, groupId, cookies.groupId, history])


    let content;
    if (error) {
        content = <tr>
            <td>{error}</td>
        </tr>;
    } else if (!isLoaded && items.length === 0) {
        content = <tr>
            <td><FontAwesomeIcon icon={faSpinner} className="fa-pulse"/> Loading...</td>
        </tr>;
    } else if (items.length === 0) {
        content =
            <tr>
                <td>Nothing found</td>
            </tr>
    } else {
        content = items.map(item => (
            <tr key={item.id}>
                <td><Link className="detail" to={{
                    pathname: `${url}/${item.id}`,
                    search: location.search
                }}>{item.id}</Link></td>
                <td className="text-nowrap"><FormattedDate value={item.startTime} month="2-digit" day="2-digit"/> <strong>
                    <FormattedTime value={item.startTime} hour="2-digit" minute="2-digit" second="2-digit" timeZone="UTC" hour12={false}/> </strong>
                </td>
                <td className="td-players">{item.totalKills}</td>
                <td className="td-fame"><FormattedNumber value={item.totalFame}/></td>

                <td className="td-guild win"><SearchLinks list={item.winners.guilds} nameExtractor={(item) => item.name} groupId={groupId} spath={'crystals'}/></td>
                <td className="td-guild lose"><SearchLinks list={item.losers.guilds} nameExtractor={(item) => item.name} groupId={groupId} spath={'crystals'}/></td>
            </tr>
        ))
    }

    return (
        <div>
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Time (UTC)</th>
                    <th className="td-players">Kills</th>
                    <th className="td-fame">Kill Fame</th>
                    <th className="td-guild">Winning Guilds</th>
                    <th className="td-guild">Loosing Guilds</th>
                </tr>
                </thead>
                <tbody>
                {content}
                </tbody>
            </Table>
            <PagingLinks offset={props.page} onPrevClick={() => {
                setIsLoaded(false)
                setItems([])
                props.setPage(props.page - 1, {method: 'push'})
            }} onNextClick={() => {
                setIsLoaded(false)
                setItems([])
                props.setPage(props.page + 1, {method: 'push'})
            }}/>
        </div>
    )
}