import React, {useEffect, useState} from "react";
import {Button, Col, Image, Row, Table} from "react-bootstrap";
import {FormattedDate, FormattedNumber, FormattedTime} from 'react-intl';
import {Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useQueryState} from 'react-router-use-location-state'
import {BattleHeader} from "./BattleHeader";
import {useCookies} from "react-cookie";
import {BattleSearch} from "./BattleSearch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBowArrow, faHandHoldingMedical, faShieldAlt, faSword, faUserShield} from "@fortawesome/pro-light-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export function Battles() {
    const [page, setPage] = useQueryState("page", 0);
    const [search, setSearch] = useQueryState("search", "");
    const [playersCount, setPlayersCount] = useQueryState("playersCount", "");
    const [startDate, setStartDate] = useQueryState("startDate", "");
    const [cookies, , removeCookie] = useCookies(['search', 'playersCount', 'startDate']);
    const [items, setItems] = useState([])
    const [redirected, setRedirected] = useState(false)
    let {path} = useRouteMatch();
    let history = useHistory()

    const onSearch = (result, groupId, playersCount, startDate) => {
        setPage(0)
        setItems([])
        if (!result) {
            removeCookie("search", {sameSite: true, path: '/'})
        }

        if (groupId) {
            history.push("/battles/" + groupId)
        } else {
            history.push("/battles")
        }

        setSearch(result);
        setPlayersCount(playersCount ? playersCount : "")
        setStartDate(startDate)
    }

    useEffect(() => {
        if (redirected) return;
        let query = "?"
        if (!search && cookies.search) {
            query += "&search=" + cookies.search
        }
        if (!playersCount && cookies.playersCount) {
            query += "&playersCount=" + cookies.playersCount
        }
        if (!startDate && cookies.startDate) {
            query += "&startDate=" + cookies.startDate
        }

        if (query !== "?") {
            setRedirected(true)
            history.replace({
                pathname: history.location.pathname,
                search: query
            });
        }

    }, [history, redirected, cookies.search, cookies.playersCount, cookies.startDate, startDate, playersCount, search, setSearch, setPlayersCount, setStartDate])

    return (
        <Switch>
            <Route exact path={path}>
                <span style={{float:'right'}} >Please, support my game 💗!<br /><iframe src="https://store.steampowered.com/widget/1124180/" frameBorder="0" width="646"
                             height="190" /></span>
                {/*<span style={{float:'right'}} >Please, support my game 💗 <a href="https://store.steampowered.com/app/1124180/Rail_Route/">Rail Route - a train dispatcher simulator</a></span>*/}
                <BattleHeader/>
                <BattleSearch onSubmit={onSearch} value={search} playersCount={playersCount} startDate={startDate}/>
                <BattleList search={search} limit={50} setPage={setPage} page={page} items={items} setItems={setItems} playersCount={playersCount} startDate={startDate}/>
            </Route>
            <Route path={`${path}/:battleId(\\d+)`}>
                <span style={{float:'right'}} >Please, support my game 💗!<br /><iframe src="https://store.steampowered.com/widget/1124180/" frameBorder="0" width="646"
                                                                                        height="190" /></span>
                {/*<span style={{float:'right'}} >Please, support my game 💗 <a href="https://store.steampowered.com/app/1124180/Rail_Route/">Rail Route - a train dispatcher simulator</a></span>*/}
                <BattleHeader onSubmit={onSearch} value={search}/>
                <BattleSearch onSubmit={onSearch} value={search} playersCount={playersCount} startDate={startDate}/>
                <Battle/>
            </Route>
            <Route exact path={`${path}/:groupId`}>
                <span style={{float:'right'}} >Please, support my game 💗!<br /><iframe src="https://store.steampowered.com/widget/1124180/" frameBorder="0" width="646"
                                                                                        height="190" /></span>
                {/*<span style={{float:'right'}} >Please, support my game 💗 <a href="https://store.steampowered.com/app/1124180/Rail_Route/">Rail Route - a train dispatcher simulator</a></span>*/}
                <BattleHeader onSubmit={onSearch} value={search}/>
                <BattleSearch onSubmit={onSearch} value={search} playersCount={playersCount} startDate={startDate}/>
                <BattleList search={search} limit={50} setPage={setPage} page={page} items={items} setItems={setItems} playersCount={playersCount} startDate={startDate}/>
            </Route>
            <Route path={`${path}/:groupId/:battleId(\\d+)`}>
                <span style={{float:'right'}} >Please, support my game 💗!<br /><iframe src="https://store.steampowered.com/widget/1124180/" frameBorder="0" width="646"
                                                                                        height="190" /></span>
                {/*<span style={{float:'right'}} >Please, support my game 💗 <a href="https://store.steampowered.com/app/1124180/Rail_Route/">Rail Route - a train dispatcher simulator</a></span>*/}
                <BattleHeader onSubmit={onSearch} value={search}/>
                <BattleSearch onSubmit={onSearch} value={search} playersCount={playersCount} startDate={startDate}/>
                <Battle/>
            </Route>
        </Switch>
    )
}

export function BattleList(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState(props.items);
    const [cookies] = useCookies(['groupId']);
    const {setItems: setParentItems} = props;
    let history = useHistory()

    let {url} = useRouteMatch();
    const location = useLocation()
    let {groupId} = useParams()

    useEffect(() => {
        if (!groupId && cookies.groupId) {
            history.replace("/battles/" + cookies.groupId)
            return
        }

        setIsLoaded(false);
        setError(null);
        let actualGroup = groupId === undefined ? "" : groupId;
        let url = `${process.env.REACT_APP_API_ROOT}/battles/?page=${encodeURIComponent(props.page)}&limit=${encodeURIComponent(props.limit)}&search=${encodeURIComponent(props.search)}&group=${actualGroup}&startDate=${props.startDate}&type=${props.type}`;
        if (props.playersCount > 0) {
            url += `&minPlayersCount=${props.playersCount}`
        }

        fetch(url)
            .then(async (result) => {
                if (result.ok) {
                    result = await result.json()
                    setItems(result);
                    if (setParentItems) {
                        setParentItems(result)
                    }
                    setIsLoaded(true);
                } else {
                    setIsLoaded(true)
                    console.error(result.statusText)
                    setError("Error fetching data. Something wrong. Maybe try it later.")
                }
            })
            .catch(
                (error) => {
                    console.error(error);
                    setIsLoaded(true);
                    setError("Error fetching data. Something wrong. Maybe try it later.")
                }
            )
    }, [props.type, props.page, props.limit, props.search, props.playersCount, props.startDate, setParentItems, groupId, cookies.groupId, history])


    let content;
    if (error) {
        content = <tr>
            <td>{error}</td>
        </tr>;
    } else if (!isLoaded && items.length === 0) {
        content = <tr>
            <td><FontAwesomeIcon icon={faSpinner} className="fa-pulse"/> Loading...</td>
        </tr>;
    } else if (items.length === 0) {
        content =
            <tr>
                <td>Nothing found</td>
            </tr>
    } else {
        content = items.map(item => (
            <tr key={item.id}>
                <td><Link className="detail" to={{
                    pathname: `${url}/${item.id}`,
                    search: location.search
                }}>{item.id}</Link></td>
                <td className="text-nowrap"><FormattedDate value={item.startTime} month="2-digit" day="2-digit"/> <strong>
                    <FormattedTime value={item.startTime} hour="2-digit" minute="2-digit" second="2-digit" timeZone="UTC" hour12={false}/> </strong>
                </td>
                <td className="td-players">{item.totalPlayers}</td>
                <td className="td-players"><FormattedNumber value={item.totalKills}/></td>
                <td className="td-fame"><FormattedNumber value={item.totalFame}/></td>

                <td className="td-alliance win d-none d-lg-table-cell"><SearchLinks list={item.winners.alliances} nameExtractor={(item) => item.name} groupId={groupId} spath={'battles'}/></td>
                <td className="td-guild win"><SearchLinks list={item.winners.guilds} nameExtractor={(item) => item.name} groupId={groupId} spath={'battles'}/></td>
                <td className="td-alliance lose d-none d-lg-table-cell"><SearchLinks list={item.losers.alliances} nameExtractor={(item) => item.name} groupId={groupId} spath={'battles'}/></td>
                <td className="td-guild lose"><SearchLinks list={item.losers.guilds} nameExtractor={(item) => item.name} groupId={groupId} spath={'battles'}/></td>
            </tr>
        ))
    }


    return (
        <div>
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Time (UTC)</th>
                    <th className="td-players">Players</th>
                    <th className="td-players">Kills</th>
                    <th className="td-fame">Kill Fame</th>
                    <th className="td-alliance d-none d-lg-table-cell">Winning&nbsp;Alliance</th>
                    <th className="td-guild">Winning Guilds</th>
                    <th className="td-alliance d-none d-lg-table-cell">Loosing&nbsp;Alliances</th>
                    <th className="td-guild">Loosing Guilds</th>
                </tr>
                </thead>
                <tbody>
                {content}
                </tbody>
            </Table>
            <PagingLinks offset={props.page} onPrevClick={() => {
                setIsLoaded(false)
                setItems([])
                props.setPage(props.page - 1, {method: 'push'})
            }} onNextClick={() => {
                setIsLoaded(false)
                setItems([])
                props.setPage(props.page + 1, {method: 'push'})
            }}/>
        </div>
    )
}


export function Battle() {
    const location = useLocation()
    let {battleId} = useParams()
    let history = useHistory()

    const [edit, setEdit] = useState(false)
    const [winner, setWinner] = useQueryState("winner", "");
    const [loser, setLoser] = useQueryState("loser", "");
    const [loadedBattle, isLoaded, error] = useBattle(battleId, location.state ? location.state.battle : null);
    const [battle, setBattle] = useState(null)
    const [title, setTitle] = useState("Battle")
    const [playerSort, setPlayerSort] = useState("fame")

    useEffect(() => {
        if (battle) {
            let t = "Battle " + battle.id
            setTitle(t)
            document.title = t
        }

        return () => {
            document.title = "Albion Killboard - battles, kills"
        }
    }, [battle])

    useEffect(() => {
        if (!loadedBattle) return
        let battle = Object.assign({}, loadedBattle);

        battle.modWinners = Object.assign({}, battle.winners)
        battle.modLosers = Object.assign({}, battle.losers)
        battle.modWinners.guilds = Array.from(battle.winners.guilds);
        battle.modLosers.guilds = Array.from(battle.losers.guilds);

        for (let index of winner.split(',')) {
            battle.modWinners.guilds.push(loadedBattle.losers.guilds[parseInt(index)])
            battle.modLosers.guilds[index] = null;
        }

        for (let index of loser.split(',')) {
            battle.modLosers.guilds.push(loadedBattle.winners.guilds[parseInt(index)])
            battle.modWinners.guilds[index] = null;
        }

        battle.modWinners.guilds = battle.modWinners.guilds.filter((el) => el != null);
        battle.modLosers.guilds = battle.modLosers.guilds.filter((el) => el != null);

        battle.modWinners.guilds.sort((a, b) => b.killFame - a.killFame)
        battle.modLosers.guilds.sort((a, b) => b.killFame - a.killFame)

        countParticipant(battle.modWinners)
        countParticipant(battle.modLosers)

        let players = battle.modWinners.players.concat(battle.modLosers.players);
        let winnerGuilds = battle.modWinners.guilds.reduce((previousValue, currentValue) => {
            previousValue.push(currentValue.id)
            return previousValue;
        }, [])
        let loserGuilds = battle.modLosers.guilds.reduce((previousValue, currentValue) => {
            previousValue.push(currentValue.id)
            return previousValue;
        }, [])

        battle.modWinners.players = players.filter(player => winnerGuilds.indexOf(player.guildId) > -1);
        battle.modLosers.players = players.filter(player => loserGuilds.indexOf(player.guildId) > -1);

        if (playerSort === "damage") {
            battle.modWinners.players.sort((a, b) => b.damageDone - a.damageDone)
            battle.modLosers.players.sort((a, b) => b.damageDone - a.damageDone)
        } else if (playerSort === "heal") {
            battle.modWinners.players.sort((a, b) => b.supportHealingDone - a.supportHealingDone)
            battle.modLosers.players.sort((a, b) => b.supportHealingDone - a.supportHealingDone)
        } else {
            battle.modWinners.players.sort((a, b) => b.killFame - a.killFame)
            battle.modLosers.players.sort((a, b) => b.killFame - a.killFame)
        }
        setBattle(battle)

    }, [loadedBattle, setBattle, winner, loser, playerSort])

    const countParticipant = (participant) => {
        participant.totalPlayers = participant.guilds.reduce((previousValue, currentValue) => previousValue + currentValue.totalPlayers, 0)
        participant.totalKills = participant.guilds.reduce((previousValue, currentValue) => previousValue + currentValue.kills, 0)
        participant.totalDeaths = participant.guilds.reduce((previousValue, currentValue) => previousValue + currentValue.deaths, 0)
        participant.totalFame = participant.guilds.reduce((previousValue, currentValue) => previousValue + currentValue.killFame, 0)
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    } else if (!battle) {
        return <div>Not found</div>;
    } else if (error) {
        return <div>Error: {error}</div>;
    }

    const addWinner = (n) => {
        let newWinner = winner === "" ? [] : winner.split(',');
        newWinner.push(n.toString())
        setWinner(newWinner.join(','))
    }

    const addLoser = (n) => {
        let newLoser = loser === "" ? [] : loser.split(',')
        newLoser.push(n.toString())
        setLoser(newLoser.join(','))
    }

    const removeWinner = (n) => {
        let newWinner = winner.split(',')
        var index = newWinner.indexOf(n.toString());
        newWinner.splice(index, 1)
        setWinner(newWinner.join(','))
    }

    const removeLoser = (n) => {
        let newLoser = loser.split(',')
        var index = newLoser.indexOf(n.toString());
        newLoser.splice(index, 1)
        setLoser(newLoser.join(','))
    }

    return (
        <div>
            <div className="float-right text-right"><Button variant="secondary" className="mr-4" onClick={() => setEdit(!edit)}>{edit ? "Done" : "I wanna win"}</Button>
                {/*<Button>Share!</Button>*/}
            </div>
            <div id="capture" className="pl-2 pr-2">
                <Row className="mt-5">
                    <Col md={12}>
                        <Row><Col><h1 className="text-center">{title}</h1></Col></Row>
                        <Row><Col className="text-center"><h2 className="title-date"><FormattedDate value={battle.startTime} month="2-digit" day="2-digit"/> <strong>
                            <FormattedTime value={battle.startTime} hour="2-digit" minute="2-digit" second="2-digit" timeZone="UTC" hour12={false}/> </strong></h2>
                        </Col></Row>
                        <Row className="pt-1 pb-3">
                            <Col md={12} xs className="text-center"><strong>Players</strong> {battle.totalPlayers} <strong className="pl-2">Kills</strong> {battle.totalKills}
                                <strong className="pl-2">Fame</strong> <FormattedNumber value={battle.totalFame}/></Col>
                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={12}>

                    </Col>
                </Row>
                <Row>
                    <Col xl={{span: 6, order: 1}} xs={{span: 12, order: 1}}><h2>Winners</h2></Col>
                    <Col xl={{span: 6, order: 2}} xs={{span: 12, order: 4}}><h2>Losers</h2></Col>
                    <Col xl={{span: 6, order: 3}} xs={{span: 12, order: 2}}><ParticipantOverview participant={battle.modWinners}/></Col>
                    <Col xl={{span: 6, order: 4}} xs={{span: 12, order: 5}}><ParticipantOverview participant={battle.modLosers}/></Col>
                    <Col xl={{span: 6, order: 5}} xs={{span: 12, order: 3}}><ParticipantGuilds guilds={battle.modWinners.guilds} className='win' edit={edit} addLoser={addLoser}
                                                                                               removeWinner={removeWinner}/></Col>
                    <Col xl={{span: 6, order: 6}} xs={{span: 12, order: 6}}><ParticipantGuilds guilds={battle.modLosers.guilds} className='lose' edit={edit} addWinner={addWinner}
                                                                                               removeLoser={removeLoser}/></Col>
                </Row>
            </div>
            <Row className="pl-2 pr-2">
                <Col xl={{span: 12, order: 7}} xs={{span: 12, order: 7}}>
                    <span className="small-info">Role compositions can be inaccurate. Item power and gear is taken from the first death / kill.</span>
                </Col>
                <Col xl={{span: 12, order: 7}} xs={{span: 12, order: 7}}>
                    <div><span className="align-middle">Sort players by:</span>
                        <Button variant="link" onClick={() => setPlayerSort("fame")}>fame</Button>
                        <Button variant="link" onClick={() => setPlayerSort("damage")}>damage</Button>
                        <Button variant="link" onClick={() => setPlayerSort("heal")}>heal</Button></div>
                </Col>

                <Col xl={{span: 6, order: 1}} xs={{span: 12, order: 8}} className="d-xl-none d-inline-block"><h2>Winners Players</h2></Col>
                <Col xl={{span: 6, order: 8}} xs={{span: 12, order: 9}}><ParticipantPlayers players={battle.modWinners.players} className='win' show={playerSort}/></Col>
                <Col xl={{span: 6, order: 2}} xs={{span: 12, order: 10}} className="d-xl-none d-inline-block"><h2>Losers Players</h2></Col>
                <Col xl={{span: 6, order: 9}} xs={{span: 12, order: 11}}><ParticipantPlayers players={battle.modLosers.players} className='lose' show={playerSort}/></Col>
            </Row>
        </div>
    );
}

function chooseName(participant) {
    if (participant.alliances && participant.alliances.length) {
        return participant.alliances[0].name
    } else if (participant.guilds && participant.guilds.length) {
        return participant.guilds[0].name
    } else if (participant.players && participant.players.length) {
        return participant.players[0].name
    }

    return "Unknown"
}

function ParticipantOverview(props) {
    if (!props.participant) {
        return <div>Not found</div>;
    } else if (props.participant.totalPlayers === 0) {
        return <div>There are no losers in this fight! Only winners!</div>
    } else {
        return (
            <Table striped bordered variant="dark">
                <thead>
                <tr>
                    <th/>
                    <th className="td-players">Players</th>
                    <th className="td-players">Kills</th>
                    <th className="td-players">Deaths</th>
                    <th className="td-fame">Kill Fame</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td/>
                    <td className="td-players">{props.participant.totalPlayers}</td>
                    <td className="td-players">{props.participant.totalKills}</td>
                    <td className="td-players">{props.participant.totalDeaths}</td>
                    <td className="td-fame"><FormattedNumber value={props.participant.totalFame}/></td>
                </tr>
                </tbody>
            </Table>
        )
    }
}

function ParticipantGuilds(props) {
    const guilds = props.guilds
    const className = props.className

    let {groupId} = useParams()

    if (!guilds) {
        return <div>Not found</div>
    } else if (!guilds.length) {
        return <div/>
    } else {
        return (
            <Table striped bordered variant="dark">
                <thead>
                <tr>
                    <th className="td-alliance-sm d-none d-sm-table-cell">Alliance</th>
                    <th className="td-guild">Guild</th>
                    <th className="td-players">Players</th>
                    <th className="td-players">AvgIP</th>
                    <th className="td-players">Kills</th>
                    <th className="td-players">Deaths</th>
                    <th className="td-fame">Kill Fame</th>
                </tr>
                </thead>
                <tbody>
                {guilds.filter(guild => guild != null).map((guild, index) => (
                    <tr key={guild.id}>
                        <td className={`${className} td-alliance d-none d-sm-table-cell`}>
                            {props.edit ?
                                <Button variant="secondary" onClick={() => {
                                    if (props.addWinner) {
                                        if (!guild.winner) {
                                            props.addWinner(guild.index)
                                        } else {
                                            props.removeLoser(guild.index)
                                        }
                                    } else if (props.addLoser) {
                                        if (guild.winner) {
                                            props.addLoser(guild.index)
                                        } else {
                                            props.removeWinner(guild.index)
                                        }
                                    }
                                }}>{props.addWinner ? "Move Left" : "Move Right"}</Button>
                                :
                                <SearchLink value={guild.alliance} groupId={groupId} spath={'battles'}/>
                            }
                        </td>
                        <td className={`${className} td-guild`}
                            title={`Tanks: ${guild.tankCount} Melee: ${guild.meleeCount} Ranged: ${guild.rangedCount} Healers: ${guild.healerCount} Supports: ${guild.supportCount}`}>
                            <Row>
                                <Col>
                                    <SearchLink value={guild.name} groupId={groupId} spath={'battles'} className="font-weight-bold"/>
                                </Col>
                                <Col md={12} className="roles">
                                    <div className="d-inline" title={`Tanks: ${guild.tankCount}`}><FontAwesomeIcon icon={faShieldAlt}/><span>{guild.tankCount}</span></div>
                                    <div className="d-inline" title={`Melee DPSs: ${guild.meleeCount}`}><FontAwesomeIcon icon={faSword}/><span>{guild.meleeCount}</span></div>
                                    <div className="d-inline" title={`Ranged DPSs: ${guild.rangedCount}`}><FontAwesomeIcon icon={faBowArrow}/><span>{guild.rangedCount}</span></div>
                                    <div className="d-inline" title={`Healers: ${guild.healerCount}`}><FontAwesomeIcon icon={faHandHoldingMedical}/><span>{guild.healerCount}</span></div>
                                    <div className="d-inline" title={`Supports: ${guild.supportCount}`}><FontAwesomeIcon icon={faUserShield}/><span>{guild.supportCount}</span></div>
                                </Col>
                            </Row>
                        </td>
                        <td className="td-players">{guild.totalPlayers}</td>
                        <td className="td-players">{guild.averageItemPower && <FormattedNumber value={guild.averageItemPower}/>}</td>
                        <td className="td-players">{guild.kills}</td>
                        <td className="td-players">{guild.deaths}</td>
                        <td className="td-fame"><FormattedNumber value={guild.killFame}/></td>
                    </tr>
                ))}
                </tbody>
            </Table>
        )
    }
}

function ParticipantPlayers(props) {
    const players = props.players;
    const className = props.className;
    const show = props.show;

    const title = () => {
        if (show === 'damage') {
            return "Damage"
        } else if (show === 'heal') {
            return "Heal"
        } else {
            return "Kill Fame"
        }
    }

    const score = (player) => {
        if (show === 'damage') {
            return <FormattedNumber value={player.damageDone}/>
        } else if (show === 'heal') {
            return <FormattedNumber value={player.supportHealingDone}/>
        } else {
            return <FormattedNumber value={player.killFame}/>
        }
    }

    if (!players) {
        return <div>Not found</div>
    } else if (!players.length) {
        return <div/>
    } else {
        return (
            <Table striped bordered variant="dark">
                <thead>
                <tr>
                    <th className="td-alliance d-none d-sm-table-cell">Alliance</th>
                    <th className="td-guild">Guild</th>
                    <th>Player</th>
                    <th className="td-players">IP</th>
                    <th className="td-players">Kills</th>
                    <th className="td-players">Deaths</th>
                    <th className="td-fame">{title()}</th>
                </tr>
                </thead>
                <tbody>
                {players.map(player => (
                    <tr key={player.name}>
                        <td className={`${className} td-alliance d-none d-sm-table-cell`}><SearchLink value={player.allianceName} spath={'battles'}/></td>
                        <td className={`${className} td-guild`}><SearchLink value={player.guildName} spath={'battles'}/></td>
                        <td>
                            {player.equipment && player.equipment.mainHand && player.equipment.mainHand.type &&
                            <ItemIcon type={player.equipment.mainHand.type}/>
                            }
                            {player.name}
                        </td>
                        <td className="td-players">{player.averageItemPower && <FormattedNumber value={player.averageItemPower}/>}</td>
                        <td className="td-players">{player.kills}</td>
                        <td className="td-players">{player.deaths}</td>
                        <td className="td-fame">{score(player)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        )
    }
}

function useBattle(id, stateBattle) {
    const [battle, setBattle] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    let {groupId} = useParams()

    useEffect(() => {
        if (stateBattle) {
            setBattle(stateBattle);
            setIsLoaded(true);
        } else {
            fetch(process.env.REACT_APP_API_ROOT + "/battles/" + id + (groupId ? "?group=" + groupId : ""))
                .then(res => res.json())
                .then(
                    (result) => {
                        result.winners.guilds.map((guild, index) => {
                            guild.index = index;
                            guild.winner = true;
                            return guild;
                        })
                        result.losers.guilds.map((guild, index) => {
                            guild.index = index;
                            guild.winner = false
                            return guild;
                        })
                        setBattle(result);
                        setIsLoaded(true);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }
    }, [id, stateBattle, groupId])

    return [battle, isLoaded, error];
}

export function PagingLinks(props) {
    if (props.offset > 0) {
        return (
            <div>
                <Button onClick={props.onPrevClick}>Previous</Button>&nbsp;
                <Button onClick={props.onNextClick}>Next</Button>
            </div>
        )
    } else {
        return (
            <div>
                <Button onClick={props.onNextClick}>Next</Button>
            </div>
        )
    }
}

function SearchLink(props) {
    if (props.groupId) {
        return <Link key={props.value} to={`/${props.spath}/${props.groupId}?search=${props.value}`} className={props.className}>{props.value}</Link>
    }
    return <Link key={props.value} to={`/${props.spath}?search=${props.value}`} className={props.className}>{props.value}</Link>
}

export function SearchLinks(props) {
    return props.list && props.list.length > 0 ? props.list.map(item => SearchLink({value: props.nameExtractor(item), groupId: props.groupId, spath: props.spath})).reduce((prev, curr) => [prev, ', ', curr]) : ''
}

function ItemIcon(props) {
    return <Image src={`https://render.albiononline.com/v1/item/${props.type}?size=35`} width={39} height={35} className="pr-1"/>
}