import {Button, Col, Form, Row} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import {getCookieExpiration} from "../../utils";

export function BattleSearch(props) {
    const [selectedValue, setSelectedValue] = useState([]);
    const [result, setResult] = useState("");
    const [query, setQuery] = useState("");
    const [options, isLoaded] = useNameHint(query)
    const [filterVisible, setFilterVisible] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['search', 'playersCount', 'startDate']);
    const [startDate, setStartDate] = useState(props.startDate ? Date.parse(props.startDate) : null);
    const [playersCount, setPlayersCount] = useState(props.playersCount);

    let {groupId} = useParams()

    useEffect(() => {
        if (props.value && props.value.length > 0) {
            setSelectedValue([{name: props.value}])
        }
    }, [props.value])

    useEffect(() => {
        if (selectedValue.length === 0 && cookies.search) {
            setQuery(cookies.search)
            setResult(cookies.search)
        }

        if (!playersCount && cookies.playersCount) {
            setPlayersCount(cookies.playersCount)
        }
        if (!startDate && cookies.startDate) {
            setStartDate(startDate)
        }
    }, [cookies.search, cookies.playersCount, cookies.startDate, startDate, playersCount, selectedValue, setSelectedValue, setQuery, setResult, setPlayersCount, setStartDate])


    const handleSubmit = (newResult) => {
        props.onSubmit(newResult ? newResult : result, groupId, playersCount, startDate ? startDate.toISOString() : "")
    }

    return (
        <Form className="nav-search" onSubmit={(event) => {
            handleSubmit()
            event.preventDefault()
        }}>
            <Row>
                <Form.Label column md="auto" className="text-nowrap">Guild / Alliance</Form.Label>
                <Col className="pb-3" xl={4}>
                    <AsyncTypeahead
                        id="search"
                        className="nav-typeahead"
                        isLoading={!isLoaded}
                        labelKey={option => `${option.name}`}
                        onSearch={(value) => setQuery(value)}
                        options={options}
                        selectHintOnEnter
                        placeholder="Search..."
                        minLength={3}
                        onChange={(value) => {
                            setSelectedValue(value)
                            if (value.length > 0) {
                                setResult(value[0].name)
                                handleSubmit(value[0].name);
                            }
                        }}
                        onInputChange={(value) => {
                            setResult(value)
                        }}
                        selected={selectedValue}
                    />
                </Col>
                <Col className="pb-3">
                    <Button variant="primary" type="submit" className="mr-3">Submit</Button>

                    {(selectedValue.length > 0 || playersCount || startDate) &&
                    <Button className="mr-3" type="button" variant={"secondary"} onClick={() => {
                        setCookie("search", result, {sameSite: true, path: '/', maxAge: getCookieExpiration()});
                        setCookie("playersCount", playersCount, {sameSite: true, path: '/', maxAge: getCookieExpiration()});
                        //setCookie("startDate", startDate, {sameSite: true, path: '/', maxAge: getCookieExpiration()});
                        handleSubmit()
                    }}>Save this search as default</Button>
                    }

                    {(cookies.search || cookies.playersCount || cookies.startDate) &&
                    <Button className="mr-3" type="button" variant={"secondary"} onClick={() => {
                        setSelectedValue([])
                        setResult("")
                        setPlayersCount("")
                        setStartDate(null)
                        removeCookie("search", {sameSite: true, path: '/'})
                        removeCookie("playersCount", {sameSite: true, path: '/'})
                        removeCookie("startDate", {sameSite: true, path: '/'})
                        props.onSubmit("")
                    }}>Clear default search</Button>
                    }
                    <Button variant="link" onClick={() => setFilterVisible(!filterVisible)}>{filterVisible ? 'less' : 'more'} filters</Button>

                </Col>
            </Row>
            {filterVisible &&
            <Row>
                <Form.Label column md="auto" className="text-nowrap">Having at least players</Form.Label>
                <Col md="auto" className="pb-3">
                    <Form.Control type="text" placeholder="Number ..." defaultValue={props.playersCount} value={playersCount} onChange={event => setPlayersCount(event.target.value)}/>
                </Col>
                <Form.Label column md="auto" className="text-nowrap"> </Form.Label>
                <Form.Label column md="auto" className="text-nowrap">Later then</Form.Label>
                <Col>
                    <DatePicker className="form-control" selected={startDate} onChange={date => setStartDate(date)} showTimeSelect timeFormat="HH:mm" dateFormat="dd.MM.yyyy HH:mm"/>
                </Col>
            </Row>
            }
        </Form>
    )
}


export function useNameHint(query) {
    const [isLoaded, setIsLoaded] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!query || query.length === 0) return;
        setIsLoaded(false);

        fetch(`${process.env.REACT_APP_API_ROOT}/names?q=${query}`)
            .then(resp => resp.json())
            .then(
                (result) => {
                    setOptions(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    setOptions([{name: error}]);
                }, [query]);
    }, [query])

    return [options, isLoaded]
}