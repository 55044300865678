import React, {useEffect} from 'react';
import './App.css';
import ReactGA from 'react-ga';
import {Battles} from "./components/battle/Battles";
import {IntlProvider} from "react-intl";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";
import {CreateGroup} from "./components/battle/Group";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import {CookiesProvider} from "react-cookie";
import {faHeart, faShieldAlt} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Crystals} from "./components/battle/Crystals";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
})

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    ReactGA.initialize('UA-000000-01');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
        <Router>
            <CookiesProvider>
            <IntlProvider locale={navigator.language}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/battles"/>
                    </Route>
                    <Route path="/battles">
                        <Battles/>
                    </Route>
                    <Route path="/crystals">
                        <Crystals/>
                    </Route>
                    <Route path="/group">
                        <CreateGroup/>
                    </Route>
                </Switch>

                <div className="footer">
                    <FontAwesomeIcon icon={faHeart}/> This blazing fast Albion Killboard was made by <strong>Losna</strong> (discord: Angel#3741). Graphics from old <a href="https://yaga.sk">yaga.sk</a> killboard
                    made by HankTheNoob (discord: HankTheNoob#2235). Ads revenues are used to cover the server costs.
                </div>
            </IntlProvider>
            </CookiesProvider>
        </Router>
    )
}

export default App;
