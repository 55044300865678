import {AsyncTypeahead} from "react-bootstrap-typeahead";
import React, {useEffect, useState} from "react";
import {BattleHeader} from "./BattleHeader";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useQueryState} from "react-router-use-location-state";
import {useForm} from "react-hook-form";
import {useNameHint} from "./BattleSearch";

export function CreateGroup(props) {
    const [query, setQuery] = useState("");
    const [options, isLoaded] = useNameHint(query)
    const [group, setGroup] = useState({shortHash: "", names: []})
    const [hash, setHash] = useQueryState("hash", "");
    const [shortHash, setShortHash] = useState(null) // for final result only
    const {register, handleSubmit, errors, setError } = useForm();


    useEffect(() => {
        if (!hash) return;

        fetch(`${process.env.REACT_APP_API_ROOT}/groups/${hash}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setGroup(data)
                setShortHash(data.shortHash)
            })
    }, [hash]);

    const setNames = (value) => {
        group.names = value
    }
    const onSubmit = (data) => {
        group.shortHash = data.shortHash;
        fetch(`${process.env.REACT_APP_API_ROOT}/groups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(group),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    if (data.error.code === "SHORTHASH_NOT_UNIQUE") {
                        setError("shortHash", "validate", "This one already taken");
                    }
                    return
                }
                setGroup(data)
                setHash(data.editHash)
                setShortHash(data.shortHash)
            })
    }

    return (
        <div>
        <BattleHeader />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col sm={1}/>
                <Col>Defined guilds / alliances will be grouped together</Col>
            </Row>
            <Form.Group as={Row} controlId="formGroup" className="mt-2">
                <Form.Label column sm={1} className="text-right">
                    Guild / Alliance
                </Form.Label>
                <Col sm={3}>
                    <AsyncTypeahead
                        id="search"
                        clearButton
                        isLoading={!isLoaded}
                        labelKey={option => `${option.name}`}
                        onSearch={(value) => setQuery(value)}
                        options={options}
                        selectHintOnEnterx
                        placeholder="Enter name..."
                        multiple
                        minLength={3}
                        onChange={(value) => {
                            setNames(value)
                        }}
                        selected={group.names}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formGroup" className="mt-2">
                <Col sm={1}/>
                <Form.Label column sm="auto">{window.location.origin}/battles/</Form.Label>
                <Col sm={1}>
                    <Form.Control isInvalid={errors.shortHash} defaultValue={group.shortHash} ref={register({pattern: {value: /^[0-9a-zA-Z-]*$/i, message: "Only letters, numbers and '-'"}})} name="shortHash" type="text" placeholder="url"/>
                    {errors.shortHash &&
                        <Form.Control.Feedback type="invalid">
                            {errors.shortHash.message}
                        </Form.Control.Feedback>
                    }
                </Col>
                <Form.Text className="text-muted" as={Col}>This will be your kill board variation URL. Empty for random.</Form.Text>


            </Form.Group>
            <Row>
                <Col sm={1}/>
                <Col sm={3}>
                    <Button variant="primary" type="submit">Save</Button>
                </Col>
            </Row>
            {shortHash &&
            <Row className="mt-5">
                <Col sm={1}/>
                <Col sm={5}>
                    <div>Access your variant of KB at: <a href={`${window.location.origin}/battles/${shortHash}`}>{window.location.origin}/battles/{shortHash}</a></div>
                    <div>Edit the definition at: <a href={`${window.location.origin}/group?hash=${group.editHash}`}>{window.location.origin}/group?hash={group.editHash}</a> (Note: save this URL safely
                        for future modification of the group)
                    </div>
                </Col>
            </Row>
            }
        </Form>
        </div>
    )
}